import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, withCtx as _withCtx, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-6045fdd2"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "w-100 mb-100 mt-20" }
const _hoisted_2 = { class: "text-cut-capped" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_MobileColumnTitle = _resolveComponent("MobileColumnTitle")!
  const _component_AmountText = _resolveComponent("AmountText")!
  const _component_Table = _resolveComponent("Table")!
  const _component_a_row = _resolveComponent("a-row")!

  return (_openBlock(), _createBlock(_component_a_row, { class: "ant-row ant-row-center" }, {
    default: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        _createVNode(_component_Table, {
          columns: _ctx.columns,
          "data-source": _ctx.mappedDataForDisplay,
          "is-loading": _ctx.isListLoading,
          "is-show-more-loading": _ctx.showMoreIsLoading,
          loadMore: _ctx.showMore
        }, {
          defaultNumberDisplay: _withCtx((items) => [
            _createVNode(_component_MobileColumnTitle, {
              columns: _ctx.columns,
              columnKey: items.column.key
            }, null, 8, ["columns", "columnKey"]),
            (items?.text)
              ? (_openBlock(), _createBlock(_component_AmountText, {
                  key: 0,
                  class: "d-inline",
                  amount: items?.text,
                  unit: items.column.unit,
                  "min-precision": items?.column.minPrecision,
                  "max-precision": items?.column.maxPrecision
                }, null, 8, ["amount", "unit", "min-precision", "max-precision"]))
              : _createCommentVNode("", true)
          ]),
          defaultTextDisplay: _withCtx((items) => [
            _createVNode(_component_MobileColumnTitle, {
              columns: _ctx.columns,
              columnKey: items.column.key
            }, null, 8, ["columns", "columnKey"]),
            _createElementVNode("div", _hoisted_2, _toDisplayString(items?.column?.prefix) + " " + _toDisplayString(items?.text), 1)
          ]),
          _: 1
        }, 8, ["columns", "data-source", "is-loading", "is-show-more-loading", "loadMore"])
      ])
    ]),
    _: 1
  }))
}