
import { defineComponent, PropType } from "vue";
import { useI18n } from "vue-i18n";
import { formatDateTime } from "@/shared/utils/date";
import { PoolCreatedBlocks_poolCreatedBlocks_PoolCreatedBlockResults_results } from "@/api/pools/__generated__/PoolCreatedBlocks";

export type PoolDetailsEpochTableItemType =
  Partial<PoolCreatedBlocks_poolCreatedBlocks_PoolCreatedBlockResults_results>;

export default defineComponent({
  props: {
    items: {
      type: Object as PropType<PoolDetailsEpochTableItemType>,
      required: true,
    },
  },
  setup() {
    const t = useI18n().t;

    return {
      t,
    };
  },
  components: {},
  methods: {
    formatDateTime,
  },
});
