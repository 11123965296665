import { computed, ref, watch, toRaw } from "vue";
import { useQuery } from "@vue/apollo-composable";
import { parseGqlResponse } from "@/shared/utils/graphql/responseParser";
import { useFetchMoreListMerge } from "@/shared/composables/useMergeList";
import { poolUpdatesGql } from "@/api/pools/poolUpdates";
import {
  PoolUpdates,
  PoolUpdates_poolUpdates_PoolUpdateResults,
  PoolUpdates_poolUpdates_PoolUpdateResults_results,
  PoolUpdatesVariables,
} from "@/api/pools/__generated__/PoolUpdates";
import { convertAmountToAda } from "@/shared/utils/numbers";

export type PoolUpdateDisplayItem = ArrayElement<
  ReturnType<typeof poolUpdateListDisplayMapper>
>;

export const poolUpdateListDisplayMapper = (
  itemList: (PoolUpdates_poolUpdates_PoolUpdateResults_results | null)[] | null
) => {
  return (itemList ?? []).map((item) => {
    return {
      transaction: item?.transaction,
      pledgeAmount: convertAmountToAda(item?.pledgeAmount),
      fixedCost: convertAmountToAda(item?.fixedCost),
      marginPercentage: item?.marginPercentage,
      activeEpochNo: item?.activeEpochNo,
      rewardAddressHash: item?.rewardAddressHash,
      ownerHashes: item?.ownerHashes,
    };
  });
};

export const useGetPoolUpdateList = (poolHash: string) => {
  const mergedItemList = ref<ReturnType<typeof poolUpdateListDisplayMapper>>(
    []
  );
  const { fetchMoreListMerge } = useFetchMoreListMerge();
  const showMoreButtonTriggered = ref(false);
  const itemList = useQuery<PoolUpdates, PoolUpdatesVariables>(
    poolUpdatesGql,
    () => ({
      filters: {
        pagination: {},
        poolHash,
      },
    })
  );

  const parsedGqlItemList = computed(() =>
    parseGqlResponse<PoolUpdates_poolUpdates_PoolUpdateResults>(
      "PoolUpdateResults",
      itemList?.result?.value
    )
  );

  watch(parsedGqlItemList, (newParsedGqlItemList) => {
    if (
      newParsedGqlItemList.data?.results &&
      newParsedGqlItemList.data?.results.length > 0
    ) {
      const mappedDisplay = poolUpdateListDisplayMapper(
        newParsedGqlItemList.data.results
      );

      const mergedList = fetchMoreListMerge<
        ArrayElement<ReturnType<typeof poolUpdateListDisplayMapper>>
      >(
        toRaw(mergedItemList.value),
        mappedDisplay ?? [],
        "id",
        showMoreButtonTriggered.value
      );

      mergedItemList.value = mergedList;
      showMoreButtonTriggered.value = false;
    }
  });

  const showMore = () => {
    showMoreButtonTriggered.value = true;
    itemList.refetch({
      filters: {
        pagination: {
          offset: mergedItemList.value.length,
        },
        poolHash: poolHash,
      },
    });
  };

  return {
    mappedDataForDisplay: mergedItemList,
    showMore,
    showMoreIsLoading: showMoreButtonTriggered,
    isLoading: itemList?.loading,
  };
};
