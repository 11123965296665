import gql from "graphql-tag";

export const poolEpochsGql = gql`
  query PoolEpochs($filters: PoolHistoryFiltersInput) {
    poolEpochs(filters: $filters) {
      ... on PoolEpochResults {
        results {
          id
          epochNo
          blocksCount
          activeStakeAmount
          delegatorRewardsAmount
          poolRewardsAmount
          delegatorsCount
        }
      }

      ... on ResponseErrors {
        errors {
          message
        }
      }
    }
  }
`;
