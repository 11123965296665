
import { defineComponent, PropType } from "vue";
import { useI18n } from "vue-i18n";
import { formatDateTime } from "@/shared/utils/date";
import { PoolUpdates_poolUpdates_PoolUpdateResults_results_transaction } from "@/api/pools/__generated__/PoolUpdates";
import PoolDetailsEpochTableItem from "@/shared/components/lists/PoolDetailsEpochTableItem.vue";
import FullWrapContainer from "@/shared/components/cards/FullWrapContainer.vue";

export type PoolDetailsTransactionTableItemType = {
  text: Partial<PoolUpdates_poolUpdates_PoolUpdateResults_results_transaction>;
};

export default defineComponent({
  props: {
    items: {
      type: Object as PropType<PoolDetailsTransactionTableItemType>,
      required: true,
    },
  },
  setup() {
    const t = useI18n().t;

    return {
      t,
    };
  },
  components: { FullWrapContainer, PoolDetailsEpochTableItem },
  methods: {
    formatDateTime,
  },
});
