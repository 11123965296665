import gql from "graphql-tag";

export const poolUpdatesGql = gql`
  query PoolUpdates($filters: PoolHistoryFiltersInput) {
    poolUpdates(filters: $filters) {
      ... on PoolUpdateResults {
        results {
          id
          transaction {
            hash
            epochNo
            slotNo
            timestamp
          }
          pledgeAmount
          fixedCost
          marginPercentage
          activeEpochNo
          rewardAddressHash
          ownerHashes
        }
      }

      ... on ResponseErrors {
        errors {
          message
        }
      }
    }
  }
`;
