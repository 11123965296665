import { computed, ref, watch, toRaw } from "vue";
import { useQuery } from "@vue/apollo-composable";
import { parseGqlResponse } from "@/shared/utils/graphql/responseParser";
import { useFetchMoreListMerge } from "@/shared/composables/useMergeList";
import { poolCreatedBlocksGql } from "@/api/pools/poolCreatedBlocks";
import {
  PoolCreatedBlocks,
  PoolCreatedBlocks_poolCreatedBlocks_PoolCreatedBlockResults,
  PoolCreatedBlocks_poolCreatedBlocks_PoolCreatedBlockResults_results,
  PoolCreatedBlocksVariables,
} from "@/api/pools/__generated__/PoolCreatedBlocks";

export type PoolLiveDelegatorsDisplayItem = ArrayElement<
  ReturnType<typeof poolCreatedBlocksListDisplayMapper>
>;

export const poolCreatedBlocksListDisplayMapper = (
  itemList:
    | (PoolCreatedBlocks_poolCreatedBlocks_PoolCreatedBlockResults_results | null)[]
    | null
) => {
  return (itemList ?? []).map((item) => {
    return {
      epoch: {
        epochNo: item?.epochNo,
        slotNo: item?.slotNo,
        timestamp: item?.timestamp,
      },
      hash: item?.hash,
      blockNo: item?.blockNo,
    };
  });
};

export const useGetPoolCreatedBlocksList = (poolHash: string) => {
  const mergedTransactionList = ref<
    ReturnType<typeof poolCreatedBlocksListDisplayMapper>
  >([]);
  const { fetchMoreListMerge } = useFetchMoreListMerge();
  const showMoreButtonTriggered = ref(false);
  const itemList = useQuery<PoolCreatedBlocks, PoolCreatedBlocksVariables>(
    poolCreatedBlocksGql,
    () => ({
      filters: {
        pagination: {},
        poolHash,
      },
    })
  );

  const parsedGqlItemList = computed(() =>
    parseGqlResponse<PoolCreatedBlocks_poolCreatedBlocks_PoolCreatedBlockResults>(
      "PoolCreatedBlockResults",
      itemList?.result?.value
    )
  );

  watch(parsedGqlItemList, (newParsedGqlItemList) => {
    if (
      newParsedGqlItemList.data?.results &&
      newParsedGqlItemList.data?.results.length > 0
    ) {
      const mappedDisplay = poolCreatedBlocksListDisplayMapper(
        newParsedGqlItemList.data.results
      );

      const mergedList = fetchMoreListMerge<
        ArrayElement<ReturnType<typeof poolCreatedBlocksListDisplayMapper>>
      >(
        toRaw(mergedTransactionList.value),
        mappedDisplay ?? [],
        "id",
        showMoreButtonTriggered.value
      );

      mergedTransactionList.value = mergedList;
      showMoreButtonTriggered.value = false;
    }
  });

  const showMore = () => {
    showMoreButtonTriggered.value = true;
    itemList.refetch({
      filters: {
        pagination: {
          offset: mergedTransactionList.value.length,
        },
        poolHash: poolHash,
      },
    });
  };

  return {
    mappedDataForDisplay: mergedTransactionList,
    showMore,
    showMoreIsLoading: showMoreButtonTriggered,
    isLoading: itemList?.loading,
  };
};
