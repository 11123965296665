import gql from "graphql-tag";

export const poolDelegationsGql = gql`
  query PoolDelegations($filters: PoolHistoryFiltersInput) {
    poolDelegations(filters: $filters) {
      ... on PoolDelegationResults {
        results {
          id
          accountHash
          amount
          transaction {
            hash
            epochNo
            slotNo
            timestamp
          }
          fromPools {
            name
            ticker
          }
          toPools {
            name
            ticker
          }
        }
      }

      ... on ResponseErrors {
        errors {
          message
        }
      }
    }
  }
`;
