import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx, renderList as _renderList, Fragment as _Fragment, createElementBlock as _createElementBlock, createElementVNode as _createElementVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-fd971532"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "w-100 mb-100 mt-20" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_MobileColumnTitle = _resolveComponent("MobileColumnTitle")!
  const _component_PoolDetailsTransactionTableItem = _resolveComponent("PoolDetailsTransactionTableItem")!
  const _component_AmountText = _resolveComponent("AmountText")!
  const _component_FullWrapContainer = _resolveComponent("FullWrapContainer")!
  const _component_Table = _resolveComponent("Table")!
  const _component_a_row = _resolveComponent("a-row")!

  return (_openBlock(), _createBlock(_component_a_row, { class: "ant-row ant-row-center" }, {
    default: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        _createVNode(_component_Table, {
          columns: _ctx.columns,
          "data-source": _ctx.mappedDataForDisplay,
          "is-loading": _ctx.isListLoading,
          "is-show-more-loading": _ctx.showMoreIsLoading,
          loadMore: _ctx.showMore
        }, {
          transaction: _withCtx((items) => [
            _createVNode(_component_MobileColumnTitle, {
              columns: _ctx.columns,
              columnKey: items.column.key
            }, null, 8, ["columns", "columnKey"]),
            _createVNode(_component_PoolDetailsTransactionTableItem, { items: items }, null, 8, ["items"])
          ]),
          defaultNumberDisplay: _withCtx((items) => [
            _createVNode(_component_MobileColumnTitle, {
              columns: _ctx.columns,
              columnKey: items.column.key
            }, null, 8, ["columns", "columnKey"]),
            (items?.text)
              ? (_openBlock(), _createBlock(_component_AmountText, {
                  key: 0,
                  class: "d-inline",
                  amount: items?.text,
                  unit: items.column.unit
                }, null, 8, ["amount", "unit"]))
              : _createCommentVNode("", true)
          ]),
          defaultTextDisplay: _withCtx((items) => [
            _createVNode(_component_MobileColumnTitle, {
              columns: _ctx.columns,
              columnKey: items.column.key
            }, null, 8, ["columns", "columnKey"]),
            _createVNode(_component_FullWrapContainer, {
              content: items?.text,
              title: _ctx.getColumnTitle(_ctx.columns, items.column.key),
              "enable-copy-to-clipboard": !items?.column?.prefix
            }, {
              prefix: _withCtx(() => [
                _createTextVNode(_toDisplayString(items?.column?.prefix), 1)
              ]),
              _: 2
            }, 1032, ["content", "title", "enable-copy-to-clipboard"])
          ]),
          ownerHashes: _withCtx((items) => [
            _createVNode(_component_MobileColumnTitle, {
              columns: _ctx.columns,
              columnKey: items.column.key
            }, null, 8, ["columns", "columnKey"]),
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(items?.text, (hash) => {
              return (_openBlock(), _createBlock(_component_FullWrapContainer, {
                content: hash,
                key: hash,
                title: _ctx.t('owner hash'),
                "enable-copy-to-clipboard": ""
              }, null, 8, ["content", "title"]))
            }), 128))
          ]),
          _: 1
        }, 8, ["columns", "data-source", "is-loading", "is-show-more-loading", "loadMore"])
      ])
    ]),
    _: 1
  }))
}