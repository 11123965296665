import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_FullWrapContainer = _resolveComponent("FullWrapContainer")!
  const _component_PoolDetailsEpochTableItem = _resolveComponent("PoolDetailsEpochTableItem")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createVNode(_component_FullWrapContainer, {
      content: _ctx.items?.text?.hash,
      title: _ctx.t('transaction hash')
    }, null, 8, ["content", "title"]),
    _createVNode(_component_PoolDetailsEpochTableItem, {
      items: {
        timestamp: _ctx.items?.text?.timestamp,
        epochNo: _ctx.items?.text?.epochNo,
        slotNo: _ctx.items?.text?.slotNo,
      }
    }, null, 8, ["items"])
  ]))
}