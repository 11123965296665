import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_a_col = _resolveComponent("a-col")!

  return (_openBlock(), _createBlock(_component_a_col, {
    lg: 0,
    md: 0,
    xl: 0,
    class: "mobile-column-title"
  }, {
    default: _withCtx(() => [
      _createTextVNode(_toDisplayString(_ctx.getColumnTitle(_ctx.columns, _ctx.columnKey)), 1)
    ]),
    _: 1
  }))
}