import { computed, ref, watch } from "vue";
import { useQuery } from "@vue/apollo-composable";
import {
  ParseGqlResponse,
  parseGqlResponse,
} from "@/shared/utils/graphql/responseParser";
import { poolDetailsGql } from "@/api/pools/poolDetails";
import {
  Pool,
  Pool_pool_Pool,
  PoolVariables,
} from "@/api/pools/__generated__/Pool";
import { convertAmountToAda } from "@/shared/utils/numbers";

const poolDetailsDisplayMapper = (item: Pool_pool_Pool | null) => {
  return {
    hash: item?.hash,
    name: item?.name,
    ticker: item?.ticker,
    description: item?.description,
    url: item?.url,
    addresses: item?.addresses,
    fixedCost: item?.fixedCost,
    marginPercentage: item?.marginPercentage,
    declaredPledgeAmount: convertAmountToAda(item?.declaredPledgeAmount),
    livePledgeAmount: convertAmountToAda(item?.livePledgeAmount),
    registrationTimestamp: item?.registrationTimestamp,
    updateTimestamp: item?.updateTimestamp,
    ownerHash: item?.ownerHash,
    rewardAddress: item?.rewardAddress,
    liveStakeAmount: item?.liveStakeAmount,
    lifetimeProfitPercentage: item?.lifetimeProfitPercentage,
    epochsWithBlocksCount: item?.epochsWithBlocksCount,
    delegatorRewardsAmount: item?.delegatorRewardsAmount,
    poolFeeAmount: convertAmountToAda(item?.poolFeeAmount),
    currentBlocksCount: item?.currentBlocksCount,
    filterCounts: {
      epochHistory: 0,
      delegationHistory: 0,
      poolsUpdate: 0,
      liveDelegators: 0,
      createdBlocks: 0,
    },
  };
};

export const useGetPoolDetails = (hash?: string) => {
  const poolDetailsDisplay = ref<ReturnType<typeof poolDetailsDisplayMapper>>();
  const apiResponseDetails = useQuery<Pool, PoolVariables>(
    poolDetailsGql,
    () => ({
      hash: hash as string,
    }),
    () => ({
      enabled: !!hash,
    })
  );

  const parsedGqlDetails = computed<ParseGqlResponse<Pool_pool_Pool>>(() =>
    parseGqlResponse<Pool_pool_Pool>("Pool", apiResponseDetails?.result?.value)
  );

  watch(parsedGqlDetails, (newParsedGqlPoolDetails) => {
    if (!newParsedGqlPoolDetails.data) return;

    poolDetailsDisplay.value = poolDetailsDisplayMapper(
      newParsedGqlPoolDetails.data
    );
  });

  return {
    mappedDataForDisplay: poolDetailsDisplay,
    isLoading: apiResponseDetails?.loading,
  };
};
