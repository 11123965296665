import gql from "graphql-tag";

export const poolDetailsGql = gql`
  query Pool($hash: String!) {
    pool(hash: $hash) {
      ... on Pool {
        hash
        name
        ticker
        description
        url
        addresses
        fixedCost
        marginPercentage
        declaredPledgeAmount
        livePledgeAmount
        registrationTimestamp
        updateTimestamp
        ownerHash
        rewardAddress
        liveStakeAmount
        lifetimeProfitPercentage
        epochsWithBlocksCount
        delegatorRewardsAmount
        poolFeeAmount
        currentBlocksCount
      }

      ... on ResponseErrors {
        errors {
          message
        }
      }
    }
  }
`;
