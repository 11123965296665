
import { defineComponent, PropType } from "vue";
import { getColumnTitle } from "@/shared/utils/table";

export default defineComponent({
  props: {
    columns: {
      type: Array as PropType<{ [key: string]: any }[]>,
      required: true,
    },
    columnKey: {
      type: String,
      required: true,
    },
  },
  components: {},
  methods: {
    getColumnTitle,
  },
});
