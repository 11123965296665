export const getColumnTitle = (
  columns: { [key: string]: any }[],
  key: string
) => {
  const foundItem = columns.find((item) => {
    if (item.dataIndex === key) {
      return true;
    }
  });

  if (foundItem?.title) {
    return foundItem?.title;
  }

  return "";
};
