import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, withCtx as _withCtx, createElementVNode as _createElementVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-ede81ba4"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "w-100 mb-100 mt-20" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_MobileColumnTitle = _resolveComponent("MobileColumnTitle")!
  const _component_PoolDetailsTransactionTableItem = _resolveComponent("PoolDetailsTransactionTableItem")!
  const _component_AmountText = _resolveComponent("AmountText")!
  const _component_FullWrapContainer = _resolveComponent("FullWrapContainer")!
  const _component_Table = _resolveComponent("Table")!
  const _component_a_row = _resolveComponent("a-row")!

  return (_openBlock(), _createBlock(_component_a_row, { class: "ant-row ant-row-center" }, {
    default: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        _createVNode(_component_Table, {
          columns: _ctx.columns,
          "data-source": _ctx.transactionListForDisplay,
          "is-loading": _ctx.isListLoading,
          "is-show-more-loading": _ctx.showMoreIsLoading,
          loadMore: _ctx.showMore
        }, {
          transaction: _withCtx((items) => [
            _createVNode(_component_MobileColumnTitle, {
              columns: _ctx.columns,
              columnKey: items.column.key
            }, null, 8, ["columns", "columnKey"]),
            _createVNode(_component_PoolDetailsTransactionTableItem, { items: items }, null, 8, ["items"])
          ]),
          defaultNumberDisplay: _withCtx((items) => [
            _createVNode(_component_MobileColumnTitle, {
              columns: _ctx.columns,
              columnKey: items.column.key
            }, null, 8, ["columns", "columnKey"]),
            (items?.text)
              ? (_openBlock(), _createBlock(_component_AmountText, {
                  key: 0,
                  class: "d-inline",
                  amount: items?.text,
                  minPrecision: 6,
                  "max-precision": 6,
                  unit: "₳"
                }, null, 8, ["amount"]))
              : _createCommentVNode("", true)
          ]),
          defaultTextDisplay: _withCtx((items) => [
            _createVNode(_component_MobileColumnTitle, {
              columns: _ctx.columns,
              columnKey: items.column.key
            }, null, 8, ["columns", "columnKey"]),
            _createVNode(_component_FullWrapContainer, {
              content: items?.text,
              title: _ctx.getColumnTitle(_ctx.columns, items.column.key),
              "enable-copy-to-clipboard": 
              items.column.enableCopyToClipboard ?? false
            
            }, null, 8, ["content", "title", "enable-copy-to-clipboard"])
          ]),
          _: 1
        }, 8, ["columns", "data-source", "is-loading", "is-show-more-loading", "loadMore"])
      ])
    ]),
    _: 1
  }))
}