
import { defineComponent } from "vue";
import { useI18n } from "vue-i18n";
import { useGetPoolDelegationList } from "@/shared/composables/api/useGetPoolDelegations";
import Table from "@/shared/components/lists/Table.vue";
import { formatDateTime } from "@/shared/utils/date";
import AmountText from "@/shared/components/typography/AmountText.vue";
import { getColumnTitle } from "@/shared/utils/table";
import MobileColumnTitle from "@/shared/components/lists/MobileColumnTitle.vue";
import PoolDetailsTransactionTableItem from "@/shared/components/lists/PoolDetailsTransactionTableItem.vue";
import FullWrapContainer from "@/shared/components/cards/FullWrapContainer.vue";

export default defineComponent({
  props: {
    poolHash: {
      type: String,
      required: true,
    },
  },
  setup(props) {
    const t = useI18n().t;

    const columns = [
      {
        title: t("Transaction"),
        dataIndex: "transaction",
        ellipsis: true,
        slots: { customRender: "transaction" },
      },

      {
        title: t("Account Hash"),
        dataIndex: "accountHash",
        ellipsis: true,
        slots: { customRender: "defaultTextDisplay" },
        enableCopyToClipboard: true,
      },
      {
        title: t("Amount"),
        dataIndex: "amount",
        ellipsis: true,
        slots: { customRender: "defaultNumberDisplay" },
      },
      {
        title: t("From  Pools"),
        dataIndex: "fromPools",
        ellipsis: true,
        slots: { customRender: "defaultTextDisplay" },
      },
      {
        title: t("To Pools"),
        dataIndex: "toPools",
        ellipsis: true,
        slots: { customRender: "defaultTextDisplay" },
      },
    ];

    const {
      mappedDataForDisplay: transactionListForDisplay,
      showMore,
      showMoreIsLoading,
      isLoading: isListLoading,
    } = useGetPoolDelegationList(props.poolHash);

    return {
      t,
      transactionListForDisplay,
      showMore,
      showMoreIsLoading,
      isListLoading,
      columns,
    };
  },
  components: {
    FullWrapContainer,
    PoolDetailsTransactionTableItem,
    MobileColumnTitle,
    AmountText,
    Table,
  },
  methods: {
    formatDateTime,
    getColumnTitle,
  },
});
