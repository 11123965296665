
import { defineComponent } from "vue";
import { useI18n } from "vue-i18n";
import Table from "@/shared/components/lists/Table.vue";
import { formatDateTime } from "@/shared/utils/date";
import AmountText from "@/shared/components/typography/AmountText.vue";
import { getColumnTitle } from "@/shared/utils/table";
import MobileColumnTitle from "@/shared/components/lists/MobileColumnTitle.vue";
import { useGetPoolEpochList } from "@/shared/composables/api/useGetPoolEpochHistory";

export default defineComponent({
  props: {
    poolHash: {
      type: String,
      required: true,
    },
  },
  setup(props) {
    const t = useI18n().t;

    const columns = [
      {
        title: t("Epoch"),
        dataIndex: "epoch",
        ellipsis: true,
        slots: { customRender: "defaultTextDisplay" },
        prefix: t("Epoch"),
      },
      {
        title: t("Block"),
        dataIndex: "blocks",
        ellipsis: true,
        slots: { customRender: "defaultTextDisplay" },
      },
      {
        title: t("Active Stake"),
        dataIndex: "activeStake",
        ellipsis: true,
        slots: { customRender: "defaultNumberDisplay" },
        unit: "₳",
      },
      {
        title: t("Delegators"),
        dataIndex: "delegatorsCount",
        ellipsis: true,
        slots: { customRender: "defaultTextDisplay" },
      },
      {
        title: t("Pool Rewards"),
        dataIndex: "rewards",
        ellipsis: true,
        slots: { customRender: "defaultNumberDisplay" },
        unit: "₳",
      },
      {
        title: t("Delegator Rewards"),
        dataIndex: "profit",
        ellipsis: true,
        slots: { customRender: "defaultNumberDisplay" },
        unit: "₳",
      },
    ];

    const {
      mappedDataForDisplay,
      showMore,
      showMoreIsLoading,
      isLoading: isListLoading,
    } = useGetPoolEpochList(props.poolHash);

    return {
      t,
      mappedDataForDisplay,
      showMore,
      showMoreIsLoading,
      isListLoading,
      columns,
    };
  },
  components: {
    MobileColumnTitle,
    AmountText,
    Table,
  },
  methods: {
    formatDateTime,
    getColumnTitle,
  },
});
