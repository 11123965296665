import { computed, ref, watch, toRaw } from "vue";
import { useQuery } from "@vue/apollo-composable";
import { parseGqlResponse } from "@/shared/utils/graphql/responseParser";
import { useFetchMoreListMerge } from "@/shared/composables/useMergeList";
import { convertAmountToAda } from "@/shared/utils/numbers";
import { poolEpochsGql } from "@/api/pools/poolEpochs";
import {
  PoolEpochs,
  PoolEpochs_poolEpochs_PoolEpochResults,
  PoolEpochs_poolEpochs_PoolEpochResults_results,
  PoolEpochsVariables,
} from "@/api/pools/__generated__/PoolEpochs";

export type PoolEpochDisplayItem = ArrayElement<
  ReturnType<typeof poolEpochListDisplayMapper>
>;

export const poolEpochListDisplayMapper = (
  itemList: (PoolEpochs_poolEpochs_PoolEpochResults_results | null)[] | null
) => {
  return (itemList ?? []).map((item) => {
    return {
      epoch: item?.epochNo,
      blocks: item?.blocksCount,
      activeStake: convertAmountToAda(item?.activeStakeAmount),
      delegatorsCount: item?.delegatorsCount,
      rewards: convertAmountToAda(item?.poolRewardsAmount),
      profit: convertAmountToAda(item?.delegatorRewardsAmount),
    };
  });
};

export const useGetPoolEpochList = (poolHash: string) => {
  const mergedItemList = ref<ReturnType<typeof poolEpochListDisplayMapper>>([]);
  const { fetchMoreListMerge } = useFetchMoreListMerge();
  const showMoreButtonTriggered = ref(false);
  const itemList = useQuery<PoolEpochs, PoolEpochsVariables>(
    poolEpochsGql,
    () => ({
      filters: {
        pagination: {},
        poolHash,
      },
    })
  );

  const parsedGqlItemList = computed(() =>
    parseGqlResponse<PoolEpochs_poolEpochs_PoolEpochResults>(
      "PoolEpochResults",
      itemList?.result?.value
    )
  );

  watch(parsedGqlItemList, (newParsedGqlItemList) => {
    if (
      newParsedGqlItemList.data?.results &&
      newParsedGqlItemList.data?.results.length > 0
    ) {
      const mappedDisplay = poolEpochListDisplayMapper(
        newParsedGqlItemList.data.results
      );

      const mergedList = fetchMoreListMerge<
        ArrayElement<ReturnType<typeof poolEpochListDisplayMapper>>
      >(
        toRaw(mergedItemList.value),
        mappedDisplay ?? [],
        "id",
        showMoreButtonTriggered.value
      );

      mergedItemList.value = mergedList;
      showMoreButtonTriggered.value = false;
    }
  });

  const showMore = () => {
    showMoreButtonTriggered.value = true;
    itemList.refetch({
      filters: {
        pagination: {
          offset: mergedItemList.value.length,
        },
        poolHash: poolHash,
      },
    });
  };

  return {
    mappedDataForDisplay: mergedItemList,
    showMore,
    showMoreIsLoading: showMoreButtonTriggered,
    isLoading: itemList?.loading,
  };
};
