
import { defineComponent } from "vue";
import { useI18n } from "vue-i18n";
import Table from "@/shared/components/lists/Table.vue";
import { getColumnTitle } from "@/shared/utils/table";
import MobileColumnTitle from "@/shared/components/lists/MobileColumnTitle.vue";
import { useGetPoolCreatedBlocksList } from "@/shared/composables/api/useGetPoolCreatedBlocks";
import PoolDetailsEpochTableItem from "@/shared/components/lists/PoolDetailsEpochTableItem.vue";
import FullWrapContainer from "@/shared/components/cards/FullWrapContainer.vue";

export default defineComponent({
  props: {
    poolHash: {
      type: String,
      required: true,
    },
  },
  setup(props) {
    const t = useI18n().t;

    const columns = [
      {
        title: t("Block"),
        dataIndex: "hash",
        ellipsis: true,
        width: "70%",
        slots: { customRender: "defaultTextDisplay" },
      },
      {
        title: t("Epoch"),
        dataIndex: "epoch",
        ellipsis: true,
        slots: { customRender: "epoch" },
      },
    ];

    const {
      mappedDataForDisplay,
      showMore,
      showMoreIsLoading,
      isLoading: isListLoading,
    } = useGetPoolCreatedBlocksList(props.poolHash);

    return {
      t,
      mappedDataForDisplay,
      showMore,
      showMoreIsLoading,
      isListLoading,
      columns,
    };
  },
  components: {
    FullWrapContainer,
    PoolDetailsEpochTableItem,
    MobileColumnTitle,
    Table,
  },
  methods: {
    getColumnTitle,
  },
});
