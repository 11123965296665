import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-ba3cce88"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "pool-details w-100" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_GlobalSearchInput = _resolveComponent("GlobalSearchInput")!
  const _component_PageHeaderTitle = _resolveComponent("PageHeaderTitle")!
  const _component_PoolDetailsCard = _resolveComponent("PoolDetailsCard")!
  const _component_a_col = _resolveComponent("a-col")!
  const _component_a_row = _resolveComponent("a-row")!
  const _component_BANCard = _resolveComponent("BANCard")!
  const _component_PoolDetailsHistoryTab = _resolveComponent("PoolDetailsHistoryTab")!
  const _component_PoolDetailsEpochsTab = _resolveComponent("PoolDetailsEpochsTab")!
  const _component_PoolDetailsPoolUpdatesTab = _resolveComponent("PoolDetailsPoolUpdatesTab")!
  const _component_PoolDetailsLiveDelegatorsTab = _resolveComponent("PoolDetailsLiveDelegatorsTab")!
  const _component_PoolDetailsCreatedBlocksTab = _resolveComponent("PoolDetailsCreatedBlocksTab")!
  const _component_TabContainer = _resolveComponent("TabContainer")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_GlobalSearchInput),
    _createVNode(_component_PageHeaderTitle, { loading: _ctx.isLoading }, {
      default: _withCtx(() => [
        _createTextVNode(_toDisplayString(_ctx.formatPoolLabel(
          _ctx.mappedDataForDisplay?.name,
          _ctx.mappedDataForDisplay?.ticker,
          _ctx.mappedDataForDisplay?.hash
        )), 1)
      ]),
      _: 1
    }, 8, ["loading"]),
    _createVNode(_component_a_row, {
      class: "ant-row ant-row-center",
      align: "middle"
    }, {
      default: _withCtx(() => [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.poolCardDetails, (item) => {
          return (_openBlock(), _createBlock(_component_a_col, {
            span: 24,
            key: item
          }, {
            default: _withCtx(() => [
              _createVNode(_component_PoolDetailsCard, {
                class: "mt-30",
                poolDetails: item,
                loading: _ctx.isLoading
              }, null, 8, ["poolDetails", "loading"])
            ]),
            _: 2
          }, 1024))
        }), 128))
      ]),
      _: 1
    }),
    _createVNode(_component_a_row, {
      class: "mt-32",
      justify: "center",
      align: "middle",
      wrap: "",
      gutter: { lg: 16 }
    }, {
      default: _withCtx(() => [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.cardDetails, (item) => {
          return (_openBlock(), _createBlock(_component_a_col, {
            lg: item.id === 2 ? 12 : 6,
            xs: 24,
            key: item
          }, {
            default: _withCtx(() => [
              _createVNode(_component_BANCard, {
                class: "mb-20",
                cardDetails: item,
                loading: _ctx.isLoading
              }, null, 8, ["cardDetails", "loading"])
            ]),
            _: 2
          }, 1032, ["lg"]))
        }), 128))
      ]),
      _: 1
    }),
    _createVNode(_component_TabContainer, {
      "tab-items": _ctx.tabItems,
      "default-active-key": "delegationHistory",
      class: "mt-32"
    }, {
      delegationHistory: _withCtx(() => [
        _createVNode(_component_PoolDetailsHistoryTab, { "pool-hash": _ctx.poolHash }, null, 8, ["pool-hash"])
      ]),
      epochHistory: _withCtx(() => [
        _createVNode(_component_PoolDetailsEpochsTab, { "pool-hash": _ctx.poolHash }, null, 8, ["pool-hash"])
      ]),
      poolsUpdate: _withCtx(() => [
        _createVNode(_component_PoolDetailsPoolUpdatesTab, { "pool-hash": _ctx.poolHash }, null, 8, ["pool-hash"])
      ]),
      liveDelegators: _withCtx(() => [
        _createVNode(_component_PoolDetailsLiveDelegatorsTab, { "pool-hash": _ctx.poolHash }, null, 8, ["pool-hash"])
      ]),
      createdBlocks: _withCtx(() => [
        _createVNode(_component_PoolDetailsCreatedBlocksTab, { "pool-hash": _ctx.poolHash }, null, 8, ["pool-hash"])
      ]),
      _: 1
    }, 8, ["tab-items"])
  ]))
}