import gql from "graphql-tag";

export const poolCreatedBlocksGql = gql`
  query PoolCreatedBlocks($filters: PoolHistoryFiltersInput) {
    poolCreatedBlocks(filters: $filters) {
      ... on PoolCreatedBlockResults {
        results {
          id
          epochNo
          slotNo
          hash
          blockNo
          timestamp
        }
      }

      ... on ResponseErrors {
        errors {
          message
        }
      }
    }
  }
`;
