import gql from "graphql-tag";

export const poolLiveDelegatorsGql = gql`
  query PoolLiveDelegators($filters: PoolHistoryFiltersInput) {
    poolLiveDelegators(filters: $filters) {
      ... on PoolLiveDelegatorResults {
        results {
          accountHash
          amount
        }
      }

      ... on ResponseErrors {
        errors {
          message
        }
      }
    }
  }
`;
