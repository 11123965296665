import { computed, ref, watch, toRaw } from "vue";
import { useQuery } from "@vue/apollo-composable";
import { parseGqlResponse } from "@/shared/utils/graphql/responseParser";
import { useFetchMoreListMerge } from "@/shared/composables/useMergeList";
import { convertAmountToAda } from "@/shared/utils/numbers";
import { poolLiveDelegatorsGql } from "@/api/pools/poolLiveDelegators";
import {
  PoolLiveDelegators,
  PoolLiveDelegators_poolLiveDelegators_PoolLiveDelegatorResults,
  PoolLiveDelegators_poolLiveDelegators_PoolLiveDelegatorResults_results,
  PoolLiveDelegatorsVariables,
} from "@/api/pools/__generated__/PoolLiveDelegators";

export type PoolLiveDelegatorsDisplayItem = ArrayElement<
  ReturnType<typeof poolLiveDelegatorsListDisplayMapper>
>;

export const poolLiveDelegatorsListDisplayMapper = (
  itemList:
    | (PoolLiveDelegators_poolLiveDelegators_PoolLiveDelegatorResults_results | null)[]
    | null
) => {
  return (itemList ?? []).map((item) => {
    return {
      accountHash: item?.accountHash,
      amount: convertAmountToAda(item?.amount),
    };
  });
};

export const useGetPoolLiveDelegatorList = (poolHash: string) => {
  const mergedTransactionList = ref<
    ReturnType<typeof poolLiveDelegatorsListDisplayMapper>
  >([]);
  const { fetchMoreListMerge } = useFetchMoreListMerge();
  const showMoreButtonTriggered = ref(false);
  const itemList = useQuery<PoolLiveDelegators, PoolLiveDelegatorsVariables>(
    poolLiveDelegatorsGql,
    () => ({
      filters: {
        pagination: {},
        poolHash,
      },
    })
  );

  const parsedGqlItemList = computed(() =>
    parseGqlResponse<PoolLiveDelegators_poolLiveDelegators_PoolLiveDelegatorResults>(
      "PoolLiveDelegatorResults",
      itemList?.result?.value
    )
  );

  watch(parsedGqlItemList, (newParsedGqlItemList) => {
    if (
      newParsedGqlItemList.data?.results &&
      newParsedGqlItemList.data?.results.length > 0
    ) {
      const mappedDisplay = poolLiveDelegatorsListDisplayMapper(
        newParsedGqlItemList.data.results
      );

      const mergedList = fetchMoreListMerge<
        ArrayElement<ReturnType<typeof poolLiveDelegatorsListDisplayMapper>>
      >(
        toRaw(mergedTransactionList.value),
        mappedDisplay ?? [],
        "accountHash",
        showMoreButtonTriggered.value
      );

      mergedTransactionList.value = mergedList;
      showMoreButtonTriggered.value = false;
    }
  });

  const showMore = () => {
    showMoreButtonTriggered.value = true;
    itemList.refetch({
      filters: {
        pagination: {
          offset: mergedTransactionList.value.length,
        },
        poolHash: poolHash,
      },
    });
  };

  return {
    mappedDataForDisplay: mergedTransactionList,
    showMore,
    showMoreIsLoading: showMoreButtonTriggered,
    isLoading: itemList?.loading,
  };
};
