import { computed, ref, watch, toRaw } from "vue";
import { useQuery } from "@vue/apollo-composable";
import { parseGqlResponse } from "@/shared/utils/graphql/responseParser";
import { useFetchMoreListMerge } from "@/shared/composables/useMergeList";
import { poolDelegationsGql } from "@/api/pools/poolDelegations";
import {
  PoolDelegations,
  PoolDelegations_poolDelegations_PoolDelegationResults,
  PoolDelegations_poolDelegations_PoolDelegationResults_results,
  PoolDelegationsVariables,
} from "@/api/pools/__generated__/PoolDelegations";
import { convertAmountToAda } from "@/shared/utils/numbers";

export type PoolDelegationDisplayItem = ArrayElement<
  ReturnType<typeof poolDelegationListDisplayMapper>
>;

export const poolDelegationListDisplayMapper = (
  itemList:
    | (PoolDelegations_poolDelegations_PoolDelegationResults_results | null)[]
    | null
) => {
  return (itemList ?? []).map((item) => {
    return {
      accountHash: item?.accountHash,
      transaction: item?.transaction,
      amount: convertAmountToAda(item?.amount),
      fromPools: item?.fromPools?.ticker,
      toPools: item?.toPools?.ticker,
    };
  });
};

export const useGetPoolDelegationList = (poolHash: string) => {
  const mergedTransactionList = ref<
    ReturnType<typeof poolDelegationListDisplayMapper>
  >([]);
  const { fetchMoreListMerge } = useFetchMoreListMerge();
  const showMoreButtonTriggered = ref(false);
  const itemList = useQuery<PoolDelegations, PoolDelegationsVariables>(
    poolDelegationsGql,
    () => ({
      filters: {
        pagination: {},
        poolHash,
      },
    })
  );

  const parsedGqlItemList = computed(() =>
    parseGqlResponse<PoolDelegations_poolDelegations_PoolDelegationResults>(
      "PoolDelegationResults",
      itemList?.result?.value
    )
  );

  watch(parsedGqlItemList, (newParsedGqlItemList) => {
    if (
      newParsedGqlItemList.data?.results &&
      newParsedGqlItemList.data?.results.length > 0
    ) {
      const mappedDisplay = poolDelegationListDisplayMapper(
        newParsedGqlItemList.data.results
      );

      const mergedList = fetchMoreListMerge<
        ArrayElement<ReturnType<typeof poolDelegationListDisplayMapper>>
      >(
        toRaw(mergedTransactionList.value),
        mappedDisplay ?? [],
        "id",
        showMoreButtonTriggered.value
      );

      mergedTransactionList.value = mergedList;
      showMoreButtonTriggered.value = false;
    }
  });

  const showMore = () => {
    showMoreButtonTriggered.value = true;
    itemList.refetch({
      filters: {
        pagination: {
          offset: mergedTransactionList.value.length,
        },
        poolHash: poolHash,
      },
    });
  };

  return {
    mappedDataForDisplay: mergedTransactionList,
    showMore,
    showMoreIsLoading: showMoreButtonTriggered,
    isLoading: itemList?.loading,
  };
};
