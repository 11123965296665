
import { computed, defineComponent, ref, watch } from "vue";
import { useI18n } from "vue-i18n";
import BANCard from "@/shared/components/cards/BANCard.vue";
import PoolDetailsCard from "@/shared/components/cards/PoolDetailsCard.vue";
import GlobalSearchInput from "@/shared/components/forms/GlobalSearchInput.vue";
import PageHeaderTitle from "@/shared/components/typography/PageHeaderTitle.vue";
import { useGetPoolDetails } from "@/shared/composables/api/useGetPoolDetails";
import { useRoute } from "vue-router";
import { formatPoolLabel } from "@/shared/utils/stringHelpers";
import TabContainer, {
  TabItem,
} from "@/shared/components/layouts/TabContainer.vue";
import get from "lodash/get";
import PoolDetailsHistoryTab from "@/shared/components/tabs/PoolDetailsHistoryTab.vue";
import PoolDetailsEpochsTab from "@/shared/components/tabs/PoolDetailsEpochsTab.vue";
import PoolDetailsPoolUpdatesTab from "@/shared/components/tabs/PoolDetailsPoolUpdatesTab.vue";
import PoolDetailsLiveDelegatorsTab from "@/shared/components/tabs/PoolDetailsLiveDelegatorsTab.vue";
import PoolDetailsCreatedBlocksTab from "@/shared/components/tabs/PoolDetailsCreatedBlocksTab.vue";

export default defineComponent({
  setup() {
    const t = useI18n().t;
    const route = useRoute();
    const poolHash = computed(() => route?.params?.hash as string);
    const { mappedDataForDisplay, isLoading } = useGetPoolDetails(
      route?.params?.hash as string
    );

    const cardDetails = computed(() => {
      return [
        {
          id: 1,
          cardTitle: t("live stake"),
          cardAmount: mappedDataForDisplay?.value?.liveStakeAmount,
        },
        {
          id: 2,
          cardTitle: t("delegator rewards"),
          cardAmount: mappedDataForDisplay?.value?.delegatorRewardsAmount,
        },
        {
          id: 3,
          cardTitle: t("pool fee"),
          cardAmount: mappedDataForDisplay?.value?.poolFeeAmount,
        },
      ];
    });

    const poolCardDetails = computed(() => {
      return [
        {
          id: 1,
          url: mappedDataForDisplay?.value?.url,
          hash: mappedDataForDisplay?.value?.hash,
          address: mappedDataForDisplay?.value?.rewardAddress,
        },
      ];
    });

    const FILTER_LABELS_MAP = {
      delegationHistory: t("Delegation history"),
      epochHistory: t("Epoch history"),
      poolsUpdate: t("Pools update"),
      liveDelegators: t("Live delegators"),
      createdBlocks: t("Created blocks"),
    };

    const tabItems = ref<TabItem[]>([]);
    watch(
      mappedDataForDisplay,
      (newMappedDataForDisplay) => {
        let filterCounts = newMappedDataForDisplay?.filterCounts ?? {};

        tabItems.value = Object.entries(FILTER_LABELS_MAP).map(
          ([key, value]) => {
            const itemCount = get(filterCounts, key, 0);
            return {
              key,
              // Show title with filter item count
              title: itemCount > 0 ? value + ` (${itemCount})` : value,
              // disabled: itemCount == 0,
            };
          }
        );
      },
      { immediate: true }
    );

    return {
      t,
      cardDetails,
      poolCardDetails,
      mappedDataForDisplay,
      isLoading,
      tabItems,
      poolHash,
    };
  },
  components: {
    PoolDetailsCreatedBlocksTab,
    PoolDetailsLiveDelegatorsTab,
    PoolDetailsPoolUpdatesTab,
    PoolDetailsEpochsTab,
    PoolDetailsHistoryTab,
    TabContainer,
    BANCard,
    PoolDetailsCard,
    GlobalSearchInput,
    PageHeaderTitle,
  },
  methods: {
    formatPoolLabel,
  },
});
